import './Login.css';
import React from 'react';
import Cookies from 'js-cookie';

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    email: "",
    password: "",
    status: false,
    isToggleOn: false
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState(prevState => ({
      isToggleOn: false
    }));
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: this.state.email, password: this.state.password })
    };
    
    fetch('https://api.gwannon.com/user/', requestOptions)
      .then(res => res.json())
      .then((json) => {
        if(json !== false) {
          Cookies.set('login', json, { expires: 60*60*24, path: '/' });
          this.setState({ 
            status: json,
            isToggleOn: true
          });
          window.location.reload(false); 
        } else {
          this.setState({ 
            status: json,
            isToggleOn: true
          })
        }
      });
  }

  render() {
    let loginMessage;
    if (this.state.status) {
      loginMessage = "Login correcto";
    } else {
      loginMessage = "Login incorrecto";
    }
    return (
      <section id="login" className="cyberpunk black">
        <div>
          <form onSubmit={this.handleSubmit} className="Login">
            <label for="email">
              Nick
            </label>
            <input
              id="email"
              name="email"
              type="text"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
            <label for="pass">
              Password
            </label>
            <input
              id="pass"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
            <div className={this.state.isToggleOn ? 'show loginMessage' : 'hide loginMessage'}>{loginMessage}</div>
            <button type="submit" className="cyberpunk">Log in</button>
          </form>
        </div>
      </section>
    );
  }
}

export default Login;