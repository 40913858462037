//npm install js-cookie --save
//npm install --save-dev dotenv
import './App.css';
import Ranking from './Ranking/Ranking';
import Misteries from './Misteries/Misteries';
import Login from './Login/Login';
import Logout from './Logout/Logout';
import Rules from './Rules/Rules';
import CountDown from './CountDown/CountDown';
import Cookies from 'js-cookie';

function App() {
  if (Cookies.get('login')) {
    return (
      <div className="app">
        <nav>
          <a href="#ranking">Ranking</a> | <a href="#whatisthis">¿Qué es esto?</a> | <a href="#rules">Reglas</a> | <Logout />
        </nav>
        <Misteries type="resolved" />
        <Misteries type="current" />
        <Ranking classBackGround="black"/>
        <Rules />
        <footer>
          <br/>
        </footer>
      </div>
    )
  } else {
    return (
      <div className="app">
        <nav>
          <a href="#ranking">Ranking</a> | <a href="#whatisthis">¿Qué es esto?</a> | <a href="#rules">Reglas</a> | <a href="#login">Login</a>
        </nav>
        <Login />
        <CountDown />
        <Ranking classBackGround="black"/>
        <Rules />
        <footer>
          <br/>
        </footer>
      </div>
    )
  }
}

export default App;
