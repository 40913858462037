import './Misteries.css';
import React, { Component } from 'react';
import Resolution from '../Resolution/Resolution';
import ViewClue from '../ViewClue/ViewClue';
import Mistery from './Mistery';
import Cookies from 'js-cookie';

class Misteries extends Component {

  state = {
    misteries: [],
    resolved: []
  }

  componentDidMount() {
    fetch('https://api.gwannon.com/user/'+Cookies.get('login'))
      .then(res => res.json())
      .then((json) => {
        this.setState({ 
          misteries: json.misteries,
          resolved: json.resolved
        })
      });
  }
  
  render() {
    if (this.props.type === "current") {
      if (this.state.misteries.length === 0) return (
        <section  className="misteries cyberpunk">
          <div>
            <h2>Has resuelto todos los misterios</h2>
          </div>
        </section>
      );
      else return (
        <section  className="misteries cyberpunk">
          <div>
            <h1>Misterios pendientes</h1>
            <div className="pending">
              {this.state.misteries.map((mistery) => (
                <div id = {"mistery" + mistery.id} className="mistery">
                  <h2>{mistery.title}</h2>
                  <div dangerouslySetInnerHTML={{ __html: mistery.text }}></div>
                  <Resolution misteryId={mistery.id} />
                  <ViewClue clueText={mistery.clue} />
                </div>
              ))}
            </div>
          </div>
        </section>)
    } else if (this.props.type === "resolved") {
      return (
        <section className="misteries cyberpunk black">
          <div>
            <h1 className="cyberpunk">Mapa de misterios</h1>
            <div className="resolved">
              {this.state.resolved.map((mistery) => (
                <Mistery misteryData={mistery} />
              ))}
            </div>
          </div>
        </section>)
    }
  }
}

export default Misteries;