import './Register.css';
import React from 'react';
import Cookies from 'js-cookie';

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    email: "",
    password: "",
    status: false,
    isToggleOn: false
  }

  handleInputChange(event) {
    event.preventDefault();
    const target = event.target;
    this.setState({
      [target.name]: target.value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState(prevState => ({
      isToggleOn: false
    }));
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: this.state.email, password: this.state.password })
    };
    
    fetch('https://api.gwannon.com/user/', requestOptions)
      .then(res => res.json())
      .then((json) => {
        if(json !== false) {
          Cookies.set('login', json, { expires: 60*60*24, path: '/' });
          this.setState({ 
            status: json,
            isToggleOn: true
          });
          window.location.reload(false); 
        } else {
          this.setState({ 
            status: json,
            isToggleOn: true
          })
        }
      });
  }

  render() {
    let registerMessage;
    if (this.state.status) {
      registerMessage = "Registro correcto";
    } else {
      registerMessage = "Registro incorrecto";
    }
    return (
      <form onSubmit={this.handleSubmit} className="Register">
        <label for="registryemail">
          Nick
        </label>
        <input
          id="registryemail"
          name="email"
          type="text"
          value={this.state.email}
          onChange={this.handleInputChange}
        />
        <label for="registrypass">
          Password
        </label>
        <input
          id="registrypass"
          name="password"
          type="password"
          value={this.state.password}
          onChange={this.handleInputChange}
        />
        <div className={this.state.isToggleOn ? 'show registerMessage' : 'hide registerMessage'}>{registerMessage}</div>
        <button type="submit" className="cyberpunk purple">Registro</button>
      </form>);
  }
}

export default Register;