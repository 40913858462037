import './Ranking.css';
import React, { Component } from 'react'

class Ranking extends Component {

  state = {
    data: []
  }
  
  componentDidMount() {
    fetch('https://api.gwannon.com/ranking/')
      .then(res => res.json())
      .then((json) => {
        this.setState({ data: json })
      });
  }  
  
  render() {
    return (
    <section id="ranking" className={"ranking cyberpunk "+this.props.classBackGround}>
      <div>
        <h1>Ranking</h1>
        <ol>
          {this.state.data.map((member, index) => (
            <li key={'position'+index}>
              {member.rank} - {member.user} ({member.date})
            </li>
          ))}
        </ol>
      </div>
    </section>)
  }
}

export default Ranking;
