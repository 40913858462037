import './Resolution.css';
import React, { Component } from 'react';
import Cookies from 'js-cookie';
import {ReactComponent as Loading} from './Loading.svg';

class Resolution extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.setState({ 
      input: '',
      misteryId: this.props.misteryId
    });
  }
  
  state = {
    input: '',
    misteryId: '',
    status: false,
    isToggleOn: false,
    loading: false
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: false,
      loading: true
    }));
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ mistery: this.props.misteryId, solution: this.state.input })
    };
    
    fetch('https://api.gwannon.com/mistery/'+Cookies.get('login'), requestOptions)
      .then(res => res.json())
      .then((json) => {
        this.setState({ 
          status: json.resolution,
          isToggleOn: true,
          loading: false
        });
        if(json.resolution) {
          setTimeout(function () {
            window.location.reload(false); 
          }, 3000);
        }
      });
  }

  changeInput(e) {
    this.setState({ 
      input: e.target.value
    });
  }

  render() {
    let errorMessage;
    if (this.state.status) {
      errorMessage = "Correcto";
    } else {
      errorMessage = "Lo siento es un ERROR";
    }

    return (
      <fieldset className="Resolution">
        <input className="cyberpunk" type="text" onChange={this.changeInput} autocapitalize="off" />
        <button className="cyberpunk green" onClick={this.handleClick}>Res0lv3r_ </button>
        <div className={this.state.loading ? 'show Loading' : 'hide Loading'}><Loading /></div>
        <p className={this.state.isToggleOn ? 'show errorMessage' : 'hide errorMessage'}>{errorMessage}</p>
      </fieldset>)
  }
}

export default Resolution;
