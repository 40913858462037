import './Logout.css';
import React, { Component } from 'react';
import Cookies from 'js-cookie';

class Logout extends Component {

  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    Cookies.set('login', '', { expires: -60*60*24, path: '/' });
    window.location.reload(false); 
  }

  render() {
    return (
      <button className="Logout" onClick={this.handleClick}>Logout</button>
    )
  }
}

export default Logout;