import React, { Component } from 'react';
class Mistery extends Component {
  render() {
    let link;
    if (this.props.misteryData.status === 'current'){
      link = <a href = {"#mistery" + this.props.misteryData.id}>Resolver</a>
    } else {
      link = '';
    }
    return (
      <div id = {"mapmistery" + this.props.misteryData.id} className={"mistery " + this.props.misteryData.status + " children" + this.props.misteryData.children + " level" + this.props.misteryData.level} data-scroll={"mistery" + this.props.misteryData.id}>
        <h2>{this.props.misteryData.title}</h2>
        {link}
      </div>
    )
  }
}

export default Mistery;