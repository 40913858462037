import './ViewClue.css';
import React, { Component } from 'react'

class ViewClue extends Component {

  constructor(props) {
    super(props);
    this.state = {isToggleOn: false};
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  render() {
    let clueView;
    if (this.state.isToggleOn) {
      clueView = this.props.clueText;
    } else {
      clueView = "";
    }

    return (
      <fieldset className="viewClue">
        <button className="cyberpunk purple" onClick={this.handleClick}>{this.state.isToggleOn ? 'Ocult4r p1sta_' : 'V3r p1sta_'}</button>
        <p className={this.state.isToggleOn ? 'show clueZone' : 'hide clueZone'}>{clueView}</p>
      </fieldset>)
  }
}

export default ViewClue;