import './CountDown.css';
import React, { Component } from 'react';
import Register from '../Register/Register';

class CountDown extends Component {

  constructor(props) {
    super(props);
    this.state = { seconds: 0, time: ""};
    this.changeSeconds = this.changeSeconds.bind(this);
  }

  changeSeconds() {
    if (this.state.seconds > 0) {
      this.setState({ 
        seconds: this.state.seconds - 1
      });
    }
  }

  componentDidMount() {
    fetch('https://api.gwannon.com/time/')
      .then(res => res.json())
      .then((json) => {
        var future = new Date(process.env.REACT_APP_REGISTRY_DATE);
        var now = new Date(json);
        let diff = Math.ceil((future.getTime() - now.getTime())/1000);
        this.setState({ seconds: diff, time: json });
      });
      setInterval(this.changeSeconds, 1000);
  }

  render() {
    var show;
    if(this.state.seconds > 0) {
      show = <Timer seconds={this.state.seconds} />
    } else if(this.state.time !== "") {
      show = <Register />;
    }

    return (
      <section className="cyberpunk countdown">
        <div>
          {show}
        </div>
      </section>)
  }
}

function Timer(props) {
  let days = Math.floor(props.seconds / (60 * 60 * 24));
  var seconds = props.seconds - (days * 60 * 60 * 24);
  let hours = Math.floor(seconds / (60 * 60));
  seconds = seconds - (hours * 60 * 60);
  let minutes= Math.floor(seconds / 60);
  seconds = seconds - (minutes * 60);
  return (
    <div className="Timer">
      <h2>El registro se abrirá en:</h2>
      <div>
        <div>{days} {days === 1 ? "día" : "días"}</div>
        <div>{hours} {hours === 1 ? "hora" : "horas"}</div>
        <div>{minutes} {minutes === 1 ? "minuto" : "minutos"}</div>
        <div>{seconds} {seconds === 1 ? "segundo" : "segundos"}</div>
      </div>
    </div>
  );
}

export default CountDown;

