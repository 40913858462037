import './Rules.css';

function Rules() {
  return (
    <section id="whatisthis" className="rules cyberpunk">
      <div>
        <h2 className="cyberpunk">¿Qué es esto?</h2>
        <div>Esto que ves es una gincana un poco friki de acertijos y pruebas que sirve de entretenimiento durante la EuskalEncounter. Si estás cansado de jugar, chatear, descargar, beber Monster, ... puedes dedicar unos minutos a ver si descifras alguno de nuestros misterios.</div>
        <div>Igual conoces el concurso <a href="https://ee30.euskalencounter.org/software-libre/hackit-solveit.html">SolveIt / HackIt</a> que se celebra todas las EuskalEncounter. Pues esto es su versión para muy principiantes, con pruebas sencillas y accesibles para todos. Con conocimientos generales normales, un poco de frikismo, otro poco de lógica y picardia y la ayuda de Google/Wikipedia puedes resolver estos misterios.</div>
      </div>
      <div id="rules">
        <h2 className="cyberpunk">Reglas</h2>
        <ul>
          <li>Todos los misterios tienen una única solución y para poder acceder a un misterio debes resolver sus predecesores en el árbol de misterios.</li>
          <li>Todas las claves de todas los misterios son en letra minúscula (alfabeto latino en inglés) y números, sin espacios, tildes, eñes, ... Por ejemplo, si la respuesta fuera "El murciélago comía ñames" la respuesta sería "elmurcielagocomianames".</li>
          <li>Cada misterio tiene un valor en puntos que cuanto más avanzas suele ser mayor. Hay misterios más avanzados que pueden tener menos puntos que sus predecesores.</li>
          <li>Cada misterio tiene una pista, verla o no verla es cuestión tuya, no quita puntos. Trata de hacerlo sin pistas, pero si te atascas échale un vistazo a ver si te ayuda. Lo principal es que sea divertido.</li>
          <li>Hay pruebas que te obligarán a buscar cosas por el recinto y alrededores de la EuskalEncounter. Si descubrís una pista no la quitéis, dejadla para otros jugadores.</li>
          <li>Habrá diferentes puzzles. Imprímelos, recórtalos y móntalos o coge el archivo de imagen y ábrelo en tu programa de dibujo preferido. Trataremos de que las imágenes tengán la máxima calidad posible.</li>
          <li>El registro es muy sencillo. Tan sencillo que ni pedimos email, solo login y password. Pon el nick que quieras (sin herir sensibilidades ni ofender a nadie) y la contraseña que quieras. Si te olvidas de tu contraseña tendrás que volver a registrarte y pasar de nuevo las pruebas.</li>
          <li>Podéis jugar en solitario o en grupo, cada uno con su cuenta compartiendo claves de los misterios o todos con la misma cuenta.</li>
        </ul>
      </div>
    </section>
  )
}

export default Rules;
